<template>
    <section class="wh-sitefooter is-pink has-small-padding-bottom">
            <div class="wh-sitefooter-inner">
                <div class="wh-sitefooter-items">
                    <div class="wh-sitefooter-item">
                        <h2 class="wh-sitefooter-item-logo is-bold is-purple is-lowercase">
                            w/h
                        </h2>
                    
                    <div class="wh-sitefooter-item-content">
                        <p class="wh-sitefooter-item-text is-mobile is-tighter is-purple is-large is-medium">
                            Vi tar varumärken och upplevelser till nästa nivå genom strategi, design, content och tech. Hos oss träffar du olika personligheter, alla experter inom sina områden. Att ha kul, skapa stolthet i teamet och få kunder som rekommenderar oss har varit våra mål sedan 2013.
                        </p>

                        <a 
                            href="https://www.google.com/maps/dir/56.8790518,14.8051421/Well+Hello+Design+%26+Communication+AB,+B%C3%A4ckgatan+12,+352+31+V%C3%A4xj%C3%B6/@56.8789913,14.8051849,21z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x4657238d25acfd31:0x40f4ed489c3f084!2m2!1d14.8052785!2d56.879025" 
                            target="_blank"
                            class="wh-sitefooter-item-text has-lesser-margin is-purple is-medium"
                        >
                            Well Hello Design & Communication AB 
                            <br>
                            Bäckgatan 12, 352 31 Växjö
                        </a>

                        <p class="wh-sitefooter-item-link-text is-lead is-purple is-medium" v-if="language == 'sv'">
                            Vill du snacka?
                        </p>
                        <p class="wh-sitefooter-item-link-text is-lead is-purple is-medium" v-if="language == 'en'">
                            Wanna talk?
                        </p>
                        <a href="mailto:business@wellhello.se" class="wh-sitefooter-item-link is-purple">
                            business@wellhello.se
                        </a>

                        <p class="wh-sitefooter-item-link-text is-purple is-medium" v-if="language == 'sv'">
                            Har du talang?
                        </p>
                        <p class="wh-sitefooter-item-link-text is-purple is-medium" v-if="language == 'en'">
                            You got talent?
                        </p>
                        <a href="mailto:work@wellhello.se" class="wh-sitefooter-item-link is-purple has-lesser-padding">
                            work@wellhello.se
                        </a>
                        
                        <!--<div class="wh-sitefooter-item-icon">
                            <a 
                                href="https://www.instagram.com/wellhello_vaxjo/" 
                                target="_blank" 
                                class="wh-sitefooter-item-link is-instagram"
                            >
                                <img src="../../assets/static/images/instagram.svg" alt="" class="wh-sitefooter-item-link-image">
                            </a>
                        </div>-->
                        
                        <a href="https://www.instagram.com/wellhello_vaxjo/" target="_blank" class="wh-sitefooter-link has-margin-top is-purple is-small is-bold">
                            Instagram
                        </a>

                        <router-link to="/integritetspolicy" 
                            class="wh-sitefooter-link is-purple is-small is-bold" 
                            v-if="language == 'sv'"
                        >
                            Integritetspolicy
                        </router-link>
                        
                        <router-link to="/integritetspolicy" 
                            class="wh-sitefooter-link is-purple is-small is-bold" 
                            v-if="language == 'en'"
                        >
                            Integrity policy
                        </router-link>

                        <a href="" class="wh-sitefooter-cc is-purple is-small is-bold" v-if="language == 'sv'">
                            Inställningar för cookies
                        </a>
                        <a href="" class="wh-sitefooter-cc is-purple is-small is-bold" v-if="language == 'en'">
                            Cookie settings
                        </a>
                    </div>
                </div>

                    <div class="wh-sitefooter-item is-desktop">
                        <p class="wh-sitefooter-item-text is-tighter is-purple is-large is-medium" v-if="language == 'sv'">
                            Vi tar varumärken och upplevelser till nästa nivå genom strategi, design, content och tech. Hos oss träffar du olika personligheter, alla experter inom sina områden. Att ha kul, skapa stolthet i teamet och få kunder som rekommenderar oss har varit våra mål sedan 2013.
                        </p>
                        <p class="wh-sitefooter-item-text is-tighter is-purple is-large is-medium" v-if="language == 'en'">
                            We level up brands and experiences with our strategy, design, content and tech wizardry. Our team of diverse personalities are all masters of their own domains. It's been our mission since 2013 to have a blast, build team pride and get our customers to shout our praises from the rooftops.
                        </p>
                    </div>
                </div>
                <div class="wh-sitefooter-scroller">
                    <div class="wh-sitefooter-scroller-container" v-if="language == 'sv'">
                        <h2 class="wh-sitefooter-scroller-text is-bold">
                            Idé- och koncept / Design / Reklam / Marknadsföring / Identitet / Copy / Content / Illustration / Video / Animering / SoMe / UX / UI / Webbutveckling / Systemutveckling / Utbildning / Appar / Automatisering / SEO / SEM / Research / Strategi / Interim / Medieplanering / Förändringsarbete / Utbildning / Rådgivning / Projektledning / Analys /
                        </h2>
                        <h2 class="wh-sitefooter-scroller-text is-bold">
                            Idé- och koncept / Design / Reklam / Marknadsföring / Identitet / Copy / Content / Illustration / Video / Animering / SoMe / UX / UI / Webbutveckling / Systemutveckling / Utbildning / Appar / Automatisering / SEO / SEM / Research / Strategi / Interim / Medieplanering / Förändringsarbete / Utbildning / Rådgivning / Projektledning / Analys /
                        </h2>
                    </div>
                    
                    <div class="wh-sitefooter-scroller-container" v-if="language == 'en'">
                        <h2 class="wh-sitefooter-scroller-text is-bold is-en">
                            Idea and koncept / Design / Advertising / Marketing / Identity / Copy / Content / Illustration / Video / Animation / SoMe / UX / UI / Web development / Software development / Education / Apps / Automation / SEO / SEM / Research / Strategy / Interim / Media planning / Change Management / Education / Consulting / Project management / Analysis /
                        </h2>
                        <h2 class="wh-sitefooter-scroller-text is-bold is-en">
                            Idea and koncept / Design / Advertising / Marketing / Identity / Copy / Content / Illustration / Video / Animation / SoMe / UX / UI / Web development / Software development / Education / Apps / Automation / SEO / SEM / Research / Strategy / Interim / Media planning / Change Management / Education / Consulting / Project management / Analysis /
                        </h2>
                    </div>
                </div>
            </div>
        </section>
</template>
<script>

export default {
    name: 'sitefooter',

    props: [
        'language'
    ],

    data () {
        return {
            
        }
    },

    methods: {
        
    }
}
</script>